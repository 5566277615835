import type { FC } from "react";
import React from "react";
import { Document, getLegalDocumentLink } from "src/lib/getLegalDocumentLink";
import { getSupportedUserLanguage } from "src/lib/i18next";
import Translate from "../Translate/Translate";

const Footer: FC<{
  title?: string;
  button?: {
    label: string;
    onClick?: () => void;
  };
}> = ({ title, button }) => {
  const language = getSupportedUserLanguage();
  return (
    <nine-footer variant="success" gradient="false" class="m0">
      {title && (
        <div slot="text">
          <h4 className="as-h2">{title}</h4>
          {button && (
            <>
              <nine-spacer></nine-spacer>
              <nine-button color="black" onClick={button.onClick}>
                {button.label}
              </nine-button>
            </>
          )}
        </div>
      )}

      <div slot="center">
        <div>
          <nine-button
            round="true"
            variant="ghost"
            arrow=""
            href="https://www.facebook.com/9amhealthofficial"
            target="_blank"
          >
            {/* FB Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              fill="none"
            >
              <path
                fill="#212121"
                d="M15.12 5.99H17V2.8c-.91-.1-1.82-.14-2.74-.14-2.72 0-4.58 1.66-4.58 4.7v2.62H6.61v3.56h3.07v9.12h3.68v-9.12h3.06l.46-3.56h-3.52V7.72c0-1.05.28-1.73 1.76-1.73Z"
              />
            </svg>
          </nine-button>
          <nine-button
            round="true"
            variant="ghost"
            arrow=""
            href="https://instagram.com/9am"
            target="_blank"
          >
            {/* Instagram Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              fill="none"
            >
              <path
                fill="#212121"
                d="M17.34 6.13a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4Zm4.6 2.42a7.59 7.59 0 0 0-.46-2.43 4.94 4.94 0 0 0-1.16-1.77 4.7 4.7 0 0 0-1.77-1.15 7.3 7.3 0 0 0-2.43-.47c-1.06-.06-1.4-.06-4.12-.06s-3.06 0-4.12.06a7.3 7.3 0 0 0-2.43.47c-.67.24-1.27.64-1.77 1.15-.51.5-.9 1.1-1.15 1.77a7.3 7.3 0 0 0-.47 2.43C2 9.6 2 9.95 2 12.67s0 3.06.06 4.12c.02.83.18 1.65.47 2.43a4.7 4.7 0 0 0 1.15 1.77c.5.5 1.1.9 1.77 1.15.78.29 1.6.45 2.43.47 1.06.06 1.4.06 4.12.06s3.06 0 4.12-.06a7.3 7.3 0 0 0 2.43-.47 4.7 4.7 0 0 0 1.77-1.15c.51-.5.9-1.1 1.16-1.77.29-.78.44-1.6.46-2.43 0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12Zm-1.8 8.12a5.6 5.6 0 0 1-.34 1.86c-.16.43-.42.82-.75 1.15a3.2 3.2 0 0 1-1.15.75 5.6 5.6 0 0 1-1.86.34 65 65 0 0 1-4 .06c-2.63 0-3 0-4-.06a5.73 5.73 0 0 1-1.94-.3 3.27 3.27 0 0 1-1.1-.75 3 3 0 0 1-.74-1.15 5.54 5.54 0 0 1-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4c0-.65.12-1.3.35-1.9A3 3 0 0 1 5 5.67c.3-.35.68-.62 1.1-.8A5.73 5.73 0 0 1 8 4.53c1 0 1.37-.06 4-.06s3 0 4 .06c.63 0 1.26.12 1.86.34.45.17.86.44 1.19.8.33.3.58.68.75 1.1.22.6.34 1.25.34 1.9a65 65 0 0 1 .06 4 65 65 0 0 1-.06 4ZM12 7.54a5.13 5.13 0 1 0 .02 10.26A5.13 5.13 0 0 0 12 7.54ZM12 16a3.33 3.33 0 1 1 0-6.66A3.33 3.33 0 0 1 12 16Z"
              />
            </svg>
          </nine-button>
          <nine-button
            round="true"
            variant="ghost"
            arrow=""
            href="https://www.linkedin.com/company/9amhealth"
            target="_blank"
          >
            {/* Linkedin Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              fill="none"
            >
              <path
                fill="#212121"
                d="M3 6.64c0-.57.2-1.04.6-1.42.41-.37.94-.55 1.59-.55.64 0 1.15.18 1.55.55.4.37.6.87.6 1.47 0 .55-.2 1.01-.59 1.38-.4.37-.94.56-1.6.56h-.01c-.64 0-1.16-.19-1.55-.56A1.9 1.9 0 0 1 3 6.64Zm.23 15.03V10.2h3.85v11.47H3.23Zm5.99 0h3.86v-6.4c0-.4.04-.72.14-.94.16-.39.4-.71.73-.98.33-.27.75-.4 1.25-.4 1.3 0 1.94.86 1.94 2.59v6.13H21v-6.58c0-1.7-.4-2.98-1.22-3.86a4.17 4.17 0 0 0-3.21-1.3c-1.5 0-2.66.63-3.5 1.9v.03h-.01l.02-.03V10.2H9.22c.02.36.03 1.5.03 3.41 0 1.91 0 4.6-.03 8.06Z"
              />
            </svg>
          </nine-button>
          <nine-button
            round="true"
            variant="ghost"
            arrow=""
            href="https://www.tiktok.com/@9am.health"
            target="_blank"
          >
            {/* TikTok Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              fill="none"
            >
              <path
                fill="#212121"
                d="M19.2 11.5V8.68c-1.33 0-2.34-.36-3-1.05a4.45 4.45 0 0 1-1.06-2.82v-.2l-2.69-.07v11.54a2.5 2.5 0 1 1-1.62-2.8V10.5a5.18 5.18 0 1 0 4.29 4.5v-4.6a7.24 7.24 0 0 0 4.08 1.1Z"
              />
            </svg>
          </nine-button>
        </div>

        <nine-spacer s="md" class="hide-max"></nine-spacer>
        <ul
          className="link-list side-by-side footer-ul"
          style={{ justifyContent: `center` }}
        >
          <li>
            <a
              href="https://join9am.com/"
              target="_blank"
              rel="noreferrer"
              className="as-tiny color-c-80"
            >
              © {new Date().getFullYear()} 9amHealth
            </a>
          </li>
          <li>
            <a
              href={getLegalDocumentLink(Document.privacyPolicy, language)}
              target="_blank"
              rel="noreferrer"
              className="as-tiny color-c-80"
            >
              <Translate msg="registerConsent.privacyPolicy" />
            </a>
          </li>
          <li>
            <a
              href={getLegalDocumentLink(Document.termsOfService, language)}
              target="_blank"
              rel="noreferrer"
              className="as-tiny color-c-80"
            >
              <Translate msg="registerConsent.termsOfService" />
            </a>
          </li>
        </ul>
      </div>
    </nine-footer>
  );
};

export default Footer;
